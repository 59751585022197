import {Avatar, Button, Collapse, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {useState} from 'react';
import {Link} from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import QuizIcon from '@mui/icons-material/Quiz';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import {User} from './User';
import {logout} from '../utils/discordManager';

export default function AccountMenu(props: {user: User, mobile?: boolean, closeMenu?: () => void}) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const closeContextMenu = () => {
		props.closeMenu?.();
	};
	return props.mobile ? (
		<>
			<ListItem key={1} button onClick={open ? handleClose : handleClick}>
				<ListItemIcon>
					<Avatar style={{marginRight: '5px'}} src={props.user.avatar || 'https://cdn.discordapp.com/embed/avatars/0.png'}/>
				</ListItemIcon>
				<ListItemText primary={props.user.username}/>
				{open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
			</ListItem>
			<Collapse onClick={closeContextMenu} key={2} in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<ListItem style={{paddingLeft: '35px'}} button component={Link} to="/quizzes">
						<ListItemIcon>
							<QuizIcon/>
						</ListItemIcon>
						<ListItemText primary="My Quizzes"/>
					</ListItem>
					{props.user.admin ? (
						<ListItem style={{paddingLeft: '35px'}} button component={Link} to="/admin">
							<ListItemIcon>
								<AdminPanelSettingsIcon/>
							</ListItemIcon>
							<ListItemText primary="Admin Settings"/>
						</ListItem>
					) : null}
					<ListItem style={{paddingLeft: '35px', color: 'red'}} button onClick={logout}>
						<ListItemIcon>
							<LogoutIcon/>
						</ListItemIcon>
						<ListItemText primary="Logout"/>
					</ListItem>
				</List>
			</Collapse>
		</>
	) : (
		<>
			<Button onClick={handleClick} style={{marginRight: '10px'}} variant="text">
				<Avatar style={{marginRight: '5px'}} src={props.user.avatar || 'https://cdn.discordapp.com/embed/avatars/0.png'}/>
				{props.user.username} {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
			</Button>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
				<MenuItem component={Link} to="/quizzes">
					<ListItemIcon>
						<QuizIcon/>
					</ListItemIcon>
					<ListItemText primary="My Quizzes"/>
				</MenuItem>
				{props.user.admin ? (
					<MenuItem component={Link} to="/admin">
						<ListItemIcon>
							<AdminPanelSettingsIcon/>
						</ListItemIcon>
						<ListItemText primary="Admin Settings"/>
					</MenuItem>
				) : null}
				<MenuItem onClick={logout} style={{color: 'red'}}>
					<ListItemIcon>
						<LogoutIcon/>
					</ListItemIcon>
					<ListItemText primary="Logout"/>
				</MenuItem>
			</Menu>
		</>
	);
}
