import {Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, Alert, IconButton} from '@mui/material';
import {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';

import Section from '../../components/Section';
import Logo from '../../components/Logo';

export default function Feedback() {
	const [field, setField] = useState('');
	const [message, setMessage] = useState(<></>);
	const [alertText, setAlertText] = useState(<></>);
	const [descriptionValue, setDescriptionValue] = useState('Description');

	function handleChange(ev: any) {
		setField(ev.target.value);
		setMessage(<></>);
		switch (ev.target.value) {
		case 'General Feedback':
			setDescriptionValue('Send us some feedback!');
			break;
		case 'Report a Problem':
			setDescriptionValue('What problem have you been having?');
			break;
		case 'Request a Feature':
			setDescriptionValue('What\' your idea?');
			break;
		case 'Your Data':
			setMessage(<><br/><br/>Please send us your Discord ID and we will assist you in your request. Please note: You may be asked for proof that you own the Discord account in question.</>);
			setDescriptionValue('What data do you want to see?');
			break;
		default:
			setDescriptionValue('Description');
			break;
		};
	};

	async function submit(ev: any) {
		ev.preventDefault();

		const feedbackForm = document.getElementById('feedbackForm');
		const formImg = document.getElementById('formImg');

		if (!feedbackForm || !formImg) return;

		feedbackForm.style.opacity = '0.5';
		formImg.style.opacity = '1';
		feedbackForm.style.pointerEvents = 'none';

		const formElements = [
			document.getElementById('formName') as HTMLInputElement,
			document.getElementById('formEmail') as HTMLInputElement,
			document.getElementById('formDesc') as HTMLInputElement,
		];

		function close() {
			if (!feedbackForm || !formImg) return;

			feedbackForm.style.pointerEvents = 'all';
			feedbackForm.style.opacity = '1';
			setAlertText(<></>);
		};

		try {
			await fetch('https://send.pageclip.co/eNnG27q3FHjq40L6kyHHCE1Lbrocm0KH', {
				method: 'POST',
				cache: 'no-cache',
				mode: 'no-cors',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: `Name=${formElements[0].value}&Email=${formElements[1].value}&Field=${field}&Desc=${formElements[2].value}`,
			});
		} catch (er) {
			formImg.style.opacity = '0';
			return setAlertText(<Alert severity="error" action={<IconButton aria-label="close" color="inherit" size="small" onClick={close}><CloseIcon fontSize="inherit"/></IconButton>}>Looks like we can&apos;t reach our feedback server right now, please try again later</Alert>);
		};

		formImg.style.opacity = '0';

		setAlertText(<Alert severity="success" action={<IconButton aria-label="close" color="inherit" size="small" onClick={close}><CloseIcon fontSize="inherit"/></IconButton>}>Thanks for your feedback {formElements[0].value}!</Alert>);

		formElements[0].value = '';
		formElements[1].value = '';
		formElements[2].value = '';
		formElements[3].value = '';
		setMessage(<></>);
		setField('');
		setDescriptionValue('Description');
	};

	return (
		<Section>
			<div className="form">
				<form id="feedbackForm" autoComplete="off" onSubmit={submit} style={{textAlign: 'center'}}>
					<Typography style={{marginTop: 30}} variant="h2">
                        Feedback
					</Typography>
					<TextField required id="formName" label="Name" inputProps={{name: 'Name'}} variant="standard"/>
					<TextField style={{width: '100%'}} id="formEmail" label="Email" type="email" inputProps={{name: 'Email'}} variant="standard"/>
					<br/><br/>

					<FormControl className="form-control" variant="standard">
						<InputLabel id="formField">Field</InputLabel>
						<Select required style={{minWidth: 120}} labelId="formField" value={field} onChange={handleChange}>
							<MenuItem value={'General Feedback'}>General Feedback</MenuItem>
							<MenuItem value={'Report a Problem'}>Report a Problem</MenuItem>
							<MenuItem value={'Request a Feature'}>Request a Feature</MenuItem>
							<MenuItem value={'Your Data'}>Your Data</MenuItem>
						</Select>
					</FormControl>
					{message}
					<br/><br/>
					<TextField required style={{width: '100%', paddingBottom: '20px'}} id="formDesc" label={descriptionValue} multiline inputProps={{name: 'Desc'}} variant="standard"/>
					<br/>
					<Button type="submit" variant="contained" color="primary">
                        Submit!
					</Button>

					<img id="formImg" alt="" src="/static/img/loading.svg" className="feedback-loading"/>
				</form>
				<div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
					{alertText}
				</div>
			</div>
			<div>
				<Logo alt="Frodo Logo" className="logo-main" background={false} text={false}/>
			</div>
		</Section>
	);
}
