import {IconButton, Snackbar} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useState} from 'react';

export default function CookieBanner() {
	const [open, setOpen] = useState(window.localStorage.getItem('cookie-dismissed') !== 'true');
	function handleClose(event: React.SyntheticEvent | Event, reason?: string) {
		if (reason === 'clickaway') return;
		window.localStorage.setItem('cookie-dismissed', 'true');
		setOpen(false);
	}
	const cookieMessage = (
		<p style={{textAlign: 'center'}}>
			This webite uses cookies to improve your experience<br/>
			You can view our cookie policy <a className="profile-name" href="/legal" target="_blank">here</a>
		</p>
	);
	const closeButton = (
		<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={handleClose}
		>
			<CloseIcon fontSize="small"/>
		</IconButton>
	);
	return (
		<div className="cookie-banner">
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				open={open}
				onClose={handleClose}
				message={cookieMessage}
				key="cookie-banner"
				action={closeButton}
			/>
		</div>
	);
}
