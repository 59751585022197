import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Menu,
	MenuItem,
	MenuList,
	useMediaQuery,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Collapse,
	Divider,
} from '@mui/material';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import MemoryIcon from '@mui/icons-material/Memory';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import BugReportIcon from '@mui/icons-material/BugReport';
import LoginIcon from '@mui/icons-material/Login';
import PolicyIcon from '@mui/icons-material/Policy';

import {commands} from '../pages/commands/commandData';
import HideOnSroll from './HideOnScroll';
import AccountMenu from './AccountMenu';
import {getDiscordUser} from './AuthenticationCheck';
import {login} from '../utils/discordManager';

export default function NavBar() {
	const [menuOpen, setMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState();
	const [commandsCollapse, setCommandsCollapse] = useState(false);
	function commandsPress(ev: any) {
		setAnchorEl(ev.currentTarget);
		setMenuOpen(true);
	};
	function menuClose() {
		setMenuOpen(false);
	};
	function commandsClick() {
		setCommandsCollapse(!commandsCollapse);
	};

	function inviteClick(ev: React.MouseEvent) {
		ev.preventDefault();
		window.open('https://invite.frodo.fun/', '', 'width=500, height=700');
	};

	function drawerItemClick(ev: React.MouseEvent<HTMLElement>) {
		setMenuOpen(false);
		const target = ev.target as HTMLLinkElement;
		if (!target.href.includes('/commands#')) return;
		const hash = target.href.split('#')[1];
		const el = document.getElementById(hash);
		if (!el) {
			setTimeout(() => {
				const el = document.getElementById(hash);
				if (el) scrollIntoView(el);
			}, 500);
			return;
		}
		scrollIntoView(el);
	};

	function scrollIntoView(el: HTMLElement) {
		const rect = el.getBoundingClientRect();
		window.scrollTo({
			top: rect.top + window.scrollY - 100,
			behavior: 'smooth',
		});
	}

	const userData = getDiscordUser();

	const mobileUser = useMediaQuery('(max-width: 959px)');
	if (!mobileUser) {
		return (
			<HideOnSroll>
				<AppBar color="transparent" position="fixed" style={{background: 'transparent', boxShadow: 'none', color: 'black'}}>
					<Toolbar>
						<Typography variant="h6" component={Link} to="/">
							<Button style={{fontSize: 'x-large', color: 'black'}}><b>Frodo</b></Button>
						</Typography>
						<Typography variant="h6">
							<Button onClick={commandsPress} color="inherit">Commands <KeyboardArrowDownIcon/></Button>
							<Menu keepMounted anchorEl={anchorEl} open={menuOpen} onClose={menuClose} style={{width: '100%'}}>
								<MenuList>
									{commands.map((command, index) => (
										<MenuItem key={index} onClick={drawerItemClick} component={Link} to={`/commands#${encodeURIComponent(command.name)}`}>
											{command.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						</Typography>
						<Typography variant="h6" component={Link} to="/leaderboard">
							<Button style={{color: 'black'}}>Leaderboard</Button>
						</Typography>
						<Typography variant="h6" component={Link} to="/feedback">
							<Button style={{color: 'black'}}>Feedback/Report Problem</Button>
						</Typography>
						<Typography variant="h6" component={Link} to="/legal">
							<Button style={{color: 'black'}}>Legal</Button>
						</Typography>
						<div style={{marginLeft: 'auto'}}>
							<Button href="https://invite.frodo.fun" onClick={inviteClick} style={{marginRight: '10px'}} variant="contained">
								Invite Me!
							</Button>
							{userData ? (
								<AccountMenu user={userData}/>
							) : (
								<Button startIcon={<img style={{height: '1em'}} src="/static/img/social/discord-white.svg"/>} onClick={login} style={{marginRight: '10px'}} variant="contained">
									Login
								</Button>
							)}
						</div>
					</Toolbar>
				</AppBar>
			</HideOnSroll>
		);
	} else {
		return (
			<div>
				<HideOnSroll>
					<AppBar color="transparent" position="fixed" style={{background: 'transparent', boxShadow: 'none', color: 'black'}}>
						<Toolbar>
							<IconButton onClick={commandsPress} edge="start" color="inherit" aria-label="menu">
								<MenuIcon/>
							</IconButton>
							<Typography variant="h6" component={Link} to="/">
								<Button style={{fontSize: 'x-large', color: 'black'}}><b>Frodo</b></Button>
							</Typography>
							<Button href="https://invite.frodo.fun" onClick={inviteClick} style={{marginRight: '10px', marginLeft: 'auto'}} variant="contained" color="primary">
                                Invite Me!
							</Button>
						</Toolbar>
					</AppBar>
				</HideOnSroll>
				<Drawer anchor="left" open={menuOpen} onClose={menuClose}>
					<div style={{width: '80vw'}}>
						<List>
							<ListItem key={0} onClick={drawerItemClick} button component={Link} to="/">
								<ListItemIcon>
									<HomeIcon/>
								</ListItemIcon>
								<ListItemText primary="Home"/>
							</ListItem>
							<ListItem key={1} button onClick={commandsClick}>
								<ListItemIcon>
									<MemoryIcon/>
								</ListItemIcon>
								<ListItemText primary="Commands"/>
								{commandsCollapse ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
							</ListItem>
							<Collapse key={2} in={commandsCollapse} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									<Divider/>
									{commands.map((command, index) => (
										<ListItem key={index} onClick={drawerItemClick} button component={Link} to={`/commands#${encodeURIComponent(command.name)}`}>
											<ListItemText style={{paddingLeft: '20px'}} primary={command.name}/>
										</ListItem>
									))}
									<Divider/>
								</List>
							</Collapse>
							<ListItem key={3} onClick={drawerItemClick} button component={Link} to="/leaderboard">
								<ListItemIcon>
									<LeaderboardIcon/>
								</ListItemIcon>
								<ListItemText primary="Leaderboard"/>
							</ListItem>
							<ListItem key={4} onClick={drawerItemClick} button component={Link} to="/feedback">
								<ListItemIcon>
									<BugReportIcon/>
								</ListItemIcon>
								<ListItemText primary="Feedback/Report Problem"/>
							</ListItem>
							<ListItem key={5} onClick={drawerItemClick} button component={Link} to="/legal">
								<ListItemIcon>
									<PolicyIcon/>
								</ListItemIcon>
								<ListItemText primary="Legal"/>
							</ListItem>
							{userData ? (
								<AccountMenu closeMenu={menuClose} mobile={true} user={userData}/>
							) : (
								<ListItem onClick={login} button style={{marginRight: '10px'}}>
									<ListItemIcon>
										<LoginIcon/>
									</ListItemIcon>
									<ListItemText primary="Login"/>
								</ListItem>
							)}
						</List>
					</div>
				</Drawer>
			</div>
		);
	};
}
