import {Link} from 'react-router-dom';

import {Command} from './command.d';

export const commands: Command[] = [
	{
		name: 'Akinator',
		description: (
			<>
				Uses the powerfull <a href="https://en.akinator.com" target="_blank" rel="noreferrer">Akinator AI</a> to guess anyone you are thinking of, all from within the discord chat using simple reactions!
			</>
		),
		syntaxDescription: 'Akinator takes no arguments!',
		fields: [
			{
				name: 'What is Akinator?',
				value: 'Akinator is a game where you get asked a set of questions and the AI will try and guess what character you are thinking of!',
			},
		],
		image: '/static/img/commands/akinator.png',
	},
	{
		name: 'Anagrams',
		description: 'Anagrams lets you play the classic countdown game as seen on TV! Once the command has been run, you must pick nine letters (vowels and consonants). Then you have 30 seconds to think of the longest word you can make up out of those letters. After 30 seconds, enter the longest word you have and check to see how well you did!',
		syntaxDescription: 'Anagrams takes no arguments!',
		image: '/static/img/commands/anagrams.png',
	},
	{
		name: 'Connect Four',
		description: (
			<>
				Play connect four against your friends directly on discord using reactions! We assume you know how to play but just incase the rules can be found here: <a href="https://www.gamesver.com/the-rules-of-connect-4-according-to-m-bradley-hasbro/" target="_blank" rel="noreferrer">gamesver.com/the-rules-of-connect-4-according-to-m-bradley-hasbro/</a>
			</>
		),
		syntaxDescription: 'Connect four only takes one argument that is the other player that you would like to play with',
		arguments: [
			{
				name: 'playertwo',
				description: 'The other player that you would like to play with',
			},
		],
		image: '/static/img/commands/connectFour.png',
	},
	{
		name: 'Hangman',
		description: 'Play the simple game of hangman with your friends!',
		syntaxDescription: 'Hangman only takes one argument that is the other player that you would like to play with',
		fields: [
			{
				name: 'How to play hangman on Frodo',
				value: (
					<ol>
						<li>Run the command and select the user that you would like to be guessing your word</li><br/>
						<li>Look at your DMs, you should have a message from Frodo and reply to the message with the word you would like to select</li>
						<ul>
							<small>
								<li>The word must be between 1 and 20 characters</li>
								<li>The word must not contain any spaces</li>
							</small>
						</ul><br/>
						<li>The person you selected can start entering in letters to guess your word by typing <code>.g &#60;letter&#62;</code></li><br/>
						<li>If you would like to stop playing, click the button on the message and it will stop</li><br/>
					</ol>
				),
			},
		],
		arguments: [
			{
				name: 'playertwo',
				description: 'The other player that you would like to play with',
			},
		],
		image: '/static/img/commands/hangman.png',
	},
	{
		name: 'Othello',
		description: `Play Othello inside Discord! Frodo's othello also has helpful features that can tell you your possible options for you to use`,
		syntaxDescription: 'Othello takes two arguments, playertwo and showmoves',
		fields: [
			{
				name: 'How to play Othello',
				value: (
					<>
						A very useful guide to othello can be found on WikiHow here: <a href="https://www.wikihow.com/Play-Othello" target="_blank" rel="noreferrer">https://www.wikihow.com/Play-Othello</a>
					</>
				),
			},
		],
		arguments: [
			{
				name: 'playertwo',
				description: 'The other player that you would like to play with',
			},
			{
				name: 'showmoves',
				description: 'If true, your possible moves will be shown to you',
			},
		],
		image: '/static/img/commands/othello.png',
	},
	{
		name: 'Rock Paper Scissors',
		actualCommandName: 'rps',
		description: `Doesn't need much of a description! Just run the command and play a game of Rock Paper Scissors!`,
		syntaxDescription: 'Rock Paper Scissors only takes one argument that is the other player that you would like to play with',
		arguments: [
			{
				name: 'playertwo',
				description: 'The other player that you would like to play with',
			},
		],
		image: '/static/img/commands/rps.png',
	},
	{
		name: 'Trivia',
		description: (
			<>
				Trivia is a fun simple game where you get given a question. Trivia features multiple difficulties and categories. A full list of categories can be viewed in Discord
				<br/>
				Trivia works by using a powerful api at <a href="https://opentdb.com/">https://opentdb.com/</a>
				<br/><br/>
				Trivia is also connected to a leaderboard that can be seen at <Link to="/leaderboard#trivia">here</Link> or by running <code>/leaderboard</code> in discord
			</>
		),
		syntaxDescription: 'Trivia takes two arguments, difficulty and category',
		arguments: [
			{
				name: 'difficulty',
				description: 'The difficulty of the trivia question',
			},
			{
				name: 'category',
				description: 'The category of the trivia question',
			},
		],
		image: '/static/img/commands/trivia.png',
	},
	{
		name: 'Tic Tac Toe',
		actualCommandName: 'ttt',
		description: `Simply run and start playing a game of Tic Tac Toe`,
		syntaxDescription: 'Tic Tac Toe takes three arguments, playertwo, gridSize and countToWin',
		arguments: [
			{
				name: 'playertwo',
				description: 'The other player that you would like to play with',
			},
			{
				name: 'gridSize',
				description: 'The size of the grid that you would like to play on',
			},
			{
				name: 'countToWin',
				description: 'The number of pieces that you would need in a row to win',
			},
		],
		image: '/static/img/commands/ttt.png',
	},
	{
		name: 'Fact',
		description: 'Get a random fact!',
		syntaxDescription: 'Fact takes no arguments!',
		image: '/static/img/commands/fact.png',
	},
	{
		name: 'Fortune',
		description: 'Get a random fortune!',
		syntaxDescription: 'Fortune takes no arguments!',
		image: '/static/img/commands/fortune.png',
	},
	{
		name: 'Insult',
		description: 'Get a random insult!',
		syntaxDescription: 'Insult takes one optional argument which is the other player that you would like to insult',
		arguments: [
			{
				name: 'user',
				description: 'The other player that you would like to insult',
			},
		],
		image: '/static/img/commands/insult.png',
	},
	{
		name: 'Joke',
		description: 'Get a random joke!',
		syntaxDescription: 'Joke takes no arguments!',
		image: '/static/img/commands/joke.png',
	},
];
