import {useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {Typography, IconButton} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import Question from './Question';
import {Question as QuestionData} from '../../components/Quiz';

interface QuestionsProps {
	questions: QuestionData[];
	onChange: (questions: QuestionData[]) => void;
}

export default function Questions(props: QuestionsProps) {
	const [questions, setQuestions] = useState<QuestionData[]>(props.questions);
	function addQuestion() {
		const newQuestions = [...questions, {
			questionId: '',
			question: '',
			correctAnswers: [''],
			incorrectAnswers: ['', '', ''],
			lastChanged: '',
		}];
		setQuestions(newQuestions);
		props.onChange(newQuestions);
	}
	function removeQuestion(index: number) {
		const newQuestions = questions.filter((_, i) => i !== index);
		setQuestions(newQuestions);
		props.onChange(newQuestions);
	}
	function dataChangeFunction(index: number) {
		return (question: QuestionData) => {
			const newQuestions = questions.map((q, i) => i === index ? question : q);
			setQuestions(newQuestions);
			props.onChange(newQuestions);
		};
	}

	function onDragEnd({destination, source}: any) {
		if (!destination) {
			return;
		}
		if (destination.index === source.index) {
			return;
		}
		const newQuestions = [...questions];
		const [removed] = newQuestions.splice(source.index, 1);
		newQuestions.splice(destination.index, 0, removed);
		setQuestions(newQuestions);
		props.onChange(newQuestions);
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable-list">
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						<div style={{position: 'relative', width: '100%'}}>
							<Typography style={{margin: '10px', textAlign: 'left'}} variant="h5">Questions</Typography>
							<IconButton style={{position: 'absolute', top: '0px', right: '10px'}} onClick={addQuestion}>
								<AddIcon/>
							</IconButton>
						</div>
						{
							questions.length === 0 ? (
								<div style={{textAlign: 'center'}}>
									You don&apos;t have any questions yet!
								</div>
							) : (
								questions.map((question, index) => {
									return (
										<Question onChange={dataChangeFunction(index)} onDelete={() => removeQuestion(index)} key={index} question={question} index={index}/>
									);
								})
							)
						}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}
