import {getDiscordUser} from '../../components/AuthenticationCheck';
import NewQuiz from './NewQuiz';
import ViewQuiz from './ViewQuiz';

export default function SelectQuiz() {
	if (!getDiscordUser()) {
		window.location.href = '/quizzes';
		return <></>;
	}
	const option = window.location.href.split('/').pop() || 'new';
	return (
		<section style={{height: 'unset', minHeight: '100vh'}}>
			{option === 'new' ? <NewQuiz/> : <ViewQuiz quizId={option}/>}
		</section>
	);
}
