import Fetch from '../../components/Fetch';
import Quiz from './Quiz';
import {QuizData} from '../../components/Quiz';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

export default function ViewQuiz(props: {quizId: string}) {
	const setLocation = useNavigate();
	return (
		<Fetch<QuizData> center={true} src={[`/api/quizzes?quizId=${props.quizId}`]} onComplete={(request) => {
			if (request[0].request.status === 200) {
				return <Quiz newQuiz={false} quiz={request[0].data}/>;
			} else {
				setLocation('/quizzes');
			}
		}} onError={() => {
			useEffect(() => {
				setLocation('/quizzes');
			});
		}}/>
	);
}
