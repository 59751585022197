import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useState} from 'react';

import {Command} from './command.d';

interface CommandSyntaxProps {
	command: Command;
}

export default function CommandSyntax({command}: CommandSyntaxProps) {
	const [open, setOpen] = useState(false);
	function handleOpen() {
		setOpen(true);
	};
	function handleClose() {
		setOpen(false);
	};

	function click(indexToShow: string) {
		return () => {
			document.getElementById(`${command.name}MainDescription`)?.classList.add('hidden');
			command.arguments?.forEach((argument, index) => {
				document.getElementById(`${command.name}ArgumentDescription${index}`)?.classList.add('hidden');
			});
			document.getElementById(indexToShow)?.classList.remove('hidden');
		};
	}

	return (
		<>
			<Button onClick={handleOpen} style={{padding: 0}}>
				<div className="commandSyntax">
                    Command Syntax
				</div>
			</Button>
			<Dialog className="commandPopup" open={open} onClose={handleClose}>
				<DialogTitle>
					<div onClick={click(`${command.name}MainDescription`)} style={{display: 'inline-block', padding: 10, cursor: 'pointer'}}>
                        /{command.actualCommandName || command.name.toLocaleLowerCase().replace(/ /g, '')}
					</div>
					{command.arguments?.map((argument, index) => (
						<span onClick={click(`${command.name}ArgumentDescription${index}`)} key={index}>
							<Button className="commandPopupText">
								{argument.name}
							</Button>
						</span>
					))}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<span id={`${command.name}MainDescription`}>
							{command.syntaxDescription}
						</span>
						{command.arguments?.map((argument, index) => (
							<span key={index} id={`${command.name}ArgumentDescription${index}`} className="hidden">
								{argument.description}
							</span>
						))}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
                        Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
