import {getDiscordUser} from '../../components/AuthenticationCheck';

import {Alert, Button, FormControl, Grid, MenuItem, Snackbar, TextField} from '@mui/material';
import {useState} from 'react';

const StatusTypes = [
	'Playing',
	'Streaming',
	'Listening',
	'Watching',
	'Competitng',
];

export default function Admin() {
	const user = getDiscordUser();
	if (!user || !user.admin) {
		window.location.href = '/';
		return <></>;
	}

	const [alertBarOpen, setAlertBarOpen] = useState(false);
	const [alertBarType, setAlertBarType] = useState<'success' | 'error'>('success');
	const [alertBarMessage, setAlertBarMessage] = useState('');
	const [statusType, setStatusType] = useState('');
	const [streamingUrlError, setStreamingUrlError] = useState(false);

	function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
		setStatusType(event.target.value);
	}

	function statusSuccess() {
		setAlertBarType('success');
		setAlertBarMessage('Bot status updated');
		setAlertBarOpen(true);
	}
	function statusError() {
		setAlertBarType('error');
		setAlertBarMessage('Failed to update bot status');
		setAlertBarOpen(true);
	}

	async function updateBotStatus(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		try {
			const statusTypeIndex = StatusTypes.indexOf(statusType);
			const status = document.getElementById('botStatus') as HTMLInputElement;
			const statusValue = status.value;
			const streamingUrlElement = document.getElementById('streamingUrl') as HTMLInputElement;
			const streamingUrl = streamingUrlElement?.value;
			if (statusTypeIndex === 1 && streamingUrl && !(streamingUrl.startsWith('https://twitch.tv/') || streamingUrl.startsWith('https://www.twitch.tv/'))) {
				setStreamingUrlError(true);
				return;
			}
			setStatusType('');
			status.value = '';
			if (streamingUrlElement) streamingUrlElement.value = '';
			const req = await fetch('/api/admin/status', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					status: statusValue,
					type: statusTypeIndex,
					url: streamingUrl,
				}),
			});
			if (req.status !== 200) throw new Error('Failed to update bot status');
			statusSuccess();
		} catch (err) {
			console.error(err);
			statusError();
		}
	}
	return (
		<section style={{height: 'unset', minHeight: '100vh'}}>
			<div style={{paddingTop: '70px'}}>
				<Grid container spacing={5}>
					<Grid item xs={false} sm={2} md={3}/>
					<Grid item xs={12} sm={8} md={6}>
						<form style={{marginLeft: '5px', marginRight: '5px'}} onSubmit={updateBotStatus}>
							<FormControl fullWidth variant="outlined">
								<TextField
									select
									required
									id="statusType"
									label="Status Type"
									value={statusType}
									onChange={handleStatusChange}
								>
									{StatusTypes.map((key) => (
										<MenuItem key={key} value={key}>
											{key}
										</MenuItem>
									))}
								</TextField>
								<br/>
								<TextField
									id="botStatus"
									type="text"
									label="Bot Status"
									required
								/>
								{statusType === StatusTypes[1] && (
									<TextField
										id="streamingUrl"
										type="text"
										label="Streaming URL"
										helperText="Stream URLs must be a twitch.tv URL to show up in discord"
										style={{marginTop: '5px'}}
										error={streamingUrlError}
									/>
								)}
								<Button variant="contained" type="submit" fullWidth={false} style={{marginTop: '20px'}}>Update</Button>
							</FormControl>
						</form>
					</Grid>
					<Grid item xs={false} sm={2} md={3}/>
				</Grid>
				<Snackbar
					open={alertBarOpen}
					onClose={() => setAlertBarOpen(false)}
				>
					<Alert onClose={() => setAlertBarOpen(false)} severity={alertBarType}>
						{alertBarMessage}
					</Alert>
				</Snackbar>
			</div>
		</section>
	);
}
