import Section from '../../components/Section';


export default function Error() {
	return (
		<Section>
			<div>
				<b>404 Error</b>
				<p>The page: &quot;{window.location.pathname}&quot; couldn&apos;t be found. Please check the url is correct or you may have clicked a wrong link.</p>
			</div>
			<div>
				<img src="/static/img/icons/nobg.svg" alt="" className="logo-main"/>
			</div>
		</Section>
	);
};
