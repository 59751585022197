import {Button} from '@mui/material';

import Logo from '../../components/Logo';

export default function FirstSection() {
	return (
		<div className="center">
			<Logo alt="Frodo Logo" className="logo-main" background={false} text={true}/>
			<BotStats/>
		</div>
	);
}

function BotStats() {
	return (
		<div className="frodoStats">
			<div>
				<Button href="https://invite.frodo.fun" variant="contained" onClick={(e) => {
					e.preventDefault();
					window.open('https://invite.frodo.fun/', '', 'width=500, height=700');
				}} color="primary" style={{fontSize: 'large', textAlign: 'center'}}>Invite Me!</Button>
			</div><br/>
			<img className="frodoStats" src={`https://img.shields.io/endpoint?url=https://frodo.fun/api/status`} alt="Frodo's status"/><br/>
			<img className="frodoStats" src={`https://img.shields.io/endpoint?url=https://frodo.fun/api/servers`} alt="Frodo's server count"/><br/>
			<img className="frodoStats" src={`https://img.shields.io/endpoint?url=https://frodo.fun/api/users`} alt="Frodo's user count"/>
		</div>
	);
}
