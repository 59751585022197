import React from 'react';
import {Button, Typography} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {commands} from './commandData';
import Command from './Command';
import CommandSyntax from './CommandSyntax';
import CommandPopup from './CommandPopup';

interface CommandProps {
}
interface CommandState {
	open: boolean;
}

export default class Commands extends React.Component<CommandProps, CommandState> {
	constructor(props: any) {
		super(props);
		this.state = {
			open: false,
		};
	}

	setOpen(open: boolean) {
		this.setState({
			open,
		});
	};

	render() {
		return (
			<>
				<CommandPopup open={this.state.open} setOpen={this.setOpen.bind(this)}/>
				<section>
					<div className="center">
						<Button onClick={() => this.setOpen(true)}><h2>Frodo&apos;s commands <ArrowDropDownIcon/></h2></Button>
					</div>
					<div onClick={() => document.getElementById(encodeURIComponent(commands[0].name))?.scrollIntoView()} className="arrowAnimated">
						<ArrowDownwardIcon/>
					</div>
				</section>
				{commands.map((command, index) => {
					return (
						<Command key={index} id={encodeURIComponent(command.name)} value={index}>
							<div>
								<Typography variant="h5">
									<b>{command.name}</b>
								</Typography>
								<div className='command-description'>{command.description}</div>
								{command.fields?.map((field, index) => (
									<div key={index}>
										<Typography variant="h6"><b>{field.name}</b></Typography>
										<div className='command-description'>{field.value}</div>
									</div>
								))}
								<CommandSyntax command={command}/>
							</div>
							<div>
								<img className="commandPicture" alt="" style={{width: '60%'}} src={command.image}/>
							</div>
						</Command>
					);
				})}
			</>
		);
	}
}
