import {Component} from 'react';

import LoadingScreen from './LoadingScreen';
import {User} from './User';

interface AuthenticationCheckProps {
	onReady: () => JSX.Element;
}
interface AuthenticationCheckState {
	loadingPage: boolean;
}

export default class AuthenticationCheck extends Component<AuthenticationCheckProps, AuthenticationCheckState> {
	fetchData: boolean;

	constructor(props: AuthenticationCheckProps) {
		super(props);
		this.fetchData = false;
		this.state = {
			loadingPage: false,
		};
		this.computeUserdata();
	}

	componentDidMount() {
		if (this.fetchData) {
			this.fetchUserData();
		}
	}

	async fetchUserData() {
		try {
			const [user, request] = await this.fetchDataFromServer();
			if (request.status === 200) {
				const clientUserExists = window.sessionStorage.getItem('user');
				window.sessionStorage.setItem('user', JSON.stringify(user));
				if (!clientUserExists) window.location.reload();
			}
			this.setState({loadingPage: false});
		} catch (e) {
			window.sessionStorage.removeItem('user');
			this.setState({loadingPage: false});
		}
	}

	getDataWhenReady() {
		this.setState({
			loadingPage: true,
		});
		this.fetchData = true;
	}

	computeUserdata() {
		const urlSearchParams = new URLSearchParams(window.location.search);
		if (urlSearchParams.has('loggedIn')) {
			this.getDataWhenReady();
			window.history.replaceState({}, '', window.location.pathname);
		} else if (urlSearchParams.has('loggedOut')) {
			window.sessionStorage.removeItem('user');
			window.history.replaceState({}, '', window.location.pathname);
		}
		if (window.sessionStorage.getItem('user')) this.checkUsersCurrentData();
		else this.fetchData = true;
	}

	async checkUsersCurrentData() {
		try {
			const clientUser = JSON.parse(window.sessionStorage.getItem('user') || '{}');
			const userRequest = await fetch('/api/discord/me');
			if (userRequest.status !== 200) {
				window.sessionStorage.removeItem('user');
				window.location.reload();
				return;
			}
			const user = await userRequest.json();
			if (JSON.stringify(user) !== JSON.stringify(clientUser)) {
				window.sessionStorage.setItem('user', JSON.stringify(user));
				window.location.reload();
			}
		} catch (e) {
			window.sessionStorage.removeItem('user');
			window.location.reload();
		}
	}

	async fetchDataFromServer(): Promise<[User | null, Response]> {
		const request = await fetch('/api/discord/me');
		if (request.status !== 200) return [null, request];
		return [await request.json(), request];
	}

	render() {
		return (
			this.state.loadingPage ? (
				<LoadingScreen/>
			) : (
				this.props.onReady()
			)
		);
	}
}

export function getDiscordUser(): User | undefined {
	if (window.sessionStorage.getItem('user')) {
		return JSON.parse(window.sessionStorage.getItem('user') || '{}');
	} else {
		return undefined;
	}
}
