import {Alert, Avatar, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Link} from '@mui/material';
import {useState} from 'react';
import CopyText from '../../components/CopyText';

import Section from '../../components/Section';
import FirstSection from './FirstSection';

export default function Home() {
	const [dhDialog, setDhDialog] = useState(false);
	const [dhCopyAlert, setDhCopyAlert] = useState(false);

	const [nlDialog, setNlDialog] = useState(false);
	const [nlCopyAlert, setNlCopyAlert] = useState(false);

	function runCopy(stateFunction: (value: boolean) => void) {
		stateFunction(true);
		setTimeout(() => stateFunction(false), 2000);
	}

	return (
		<div>
			<section>
				<div style={{position: 'relative', zIndex: '0', height: '100vh', width: '100vw'}}>
					<FirstSection/>
					<div className="wave" style={{backgroundImage: 'url("/static/img/wave.svg")'}}>&nbsp;</div>
				</div>
			</section>
			<Section style={{backgroundImage: 'none', backgroundColor: 'white'}}>
				<div>
					<b style={{fontSize: 'x-large'}}>Who is Frodo?</b>
					<br/>
					<p>
						Frodo is a minigames discord bot that is constantly being developed. It has many fun and polished games and more are coming. Frodo features many games like tic tac toe, connect 4 and trivia!
					</p>
					<br/><br/>
					<b style={{fontSize: 'x-large'}}>Why Frodo?</b>
					<p>
						Frodo has all the games in one simple place, no need to move between different bots, just check out all the wide range of commands that we offer and take your pick!
					</p>
				</div>
				<div>
					<img alt="Frodo preview" width="300" src="/static/img/frodoProfile-new.svg" className="frodoPreview"/>
				</div>
			</Section>
			<Section style={{backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)'}}>
				<div className="ppContainer" style={{position: 'relative', textAlign: 'center'}}>
					<img alt="Toffee1347" src="/static/img/Toffee1347.jpg" className="pp" style={{top: '10px', left: '10px'}}/>
					<img alt="ZigZagZat" src="/static/img/ZigZagZat.svg" className="pp" style={{bottom: '10px', right: '10px'}}/>
				</div>
				<div>
					<b style={{fontSize: 'x-large'}}>Who made Frodo?</b>
					<p>
						Frodo was developed by <span className="profile-name" onClick={() => setDhDialog(true)}>Daniel Howard</span> and <span className="profile-name" onClick={() => setNlDialog(true)}>Noah Lavelle</span>. We starting making Frodo as a small project and then decided to try and make a bot that people actually use!
					</p>
					<br/><br/>
					<b style={{fontSize: 'x-large'}}>How is Frodo made?</b>
					<p>
						Frodo is made with <Link target="_blank" href="https://discord.js.org/#/">Discord.js</Link> and is all made through typeScript. This website is made with <Link target="_blank" href="https://reactjs.org/">Reactjs</Link> with the <Link target="_blank" href="https://mui.com/">Mui</Link> component libary. All our code is stored on gitlab and can be see at <Link target="_blank" href="https://gitlab.com/frodobot">https://gitlab.com/frodobot</Link>.
					</p>
				</div>
			</Section>
			<Dialog open={dhDialog} onClose={() => setDhDialog(false)}>
				<DialogTitle>Daniel Howard</DialogTitle>
				<DialogContent>
					<Avatar className="pfpDialog" style={{textAlign: 'center'}} sx={{width: 100, height: 100}} alt="Daniel Howard" src="/static/img/Toffee1347.jpg"/>
					<p style={{textAlign: 'center'}}>
						Hi 👋! I&apos;m Daniel and I am a junior software developer who is currently working on Frodo. Check out my socials below:
					</p>
					<Collapse in={dhCopyAlert}>
						<Alert severity="info">
							Copied to clipboard!
						</Alert>
					</Collapse>
					<div className="socials">
						<a rel="noreferrer" href="https://github.com/toffee1347/" target="_blank">
							<img alt="GitHub" src="/static/img/social/github.svg" className="social"/>
						</a>
						<a rel="noreferrer" href="https://gitlab.com/danielhoward/" target="_blank">
							<img alt="GitLab" src="/static/img/social/gitlab.svg" className="social"/>
						</a>
						<CopyText text="Toffee1347#5505" onCopy={() => runCopy(setDhCopyAlert)}>
							<img alt="Discord" src="/static/img/social/discord.svg" className="social"/>
						</CopyText>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDhDialog(false)}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={nlDialog} onClose={() => setNlDialog(false)}>
				<DialogTitle>Noah Lavelle</DialogTitle>
				<DialogContent>
					<Avatar className="pfpDialog" style={{textAlign: 'center'}} sx={{width: 100, height: 100}} alt="Noah Lavelle" src="/static/img/ZigZagZat.svg"/>
					<p style={{textAlign: 'center'}}>
						Hi 👋! I&apos;m Noah and I am a junior software developer who is currently working on Frodo. Check out my socials below:
					</p>
					<Collapse in={nlCopyAlert}>
						<Alert severity="info">
							Copied to clipboard!
						</Alert>
					</Collapse>
					<div className="socials">
						<a rel="noreferrer" href="https://github.com/noahlavelle/" target="_blank">
							<img alt="GitHub" src="/static/img/social/github.svg" className="social"/>
						</a>
						<CopyText text="ZigZagZat#5620" onCopy={() => runCopy(setNlCopyAlert)}>
							<img alt="Discord" src="/static/img/social/discord.svg" className="social"/>
						</CopyText>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setNlDialog(false)}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
