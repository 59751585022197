import {Grid, Typography} from '@mui/material';
import {useState} from 'react';
import {marked} from 'marked';

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

function dateToString(date: Date) {
	return `${date.getHours()}:${date.getMinutes()} ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

function getData(url: string, type: 'text' | 'json', onError: (error: Error) => void) {
	return fetch(url)
		.then((r) => {
			return r[type]();
		})
		.catch((e) => {
			onError(e);
		});
}

export default function Legal() {
	const [data, setData] = useState('');
	let error = false;

	function onError(e: Error) {
		error = true;
		console.error(e);
		setData(`
			<div>
				<h2 style="color: red;">There was an error when fetching the data!</h2>
				<p>
					To view the privacy policy and terms of service, please visit the following links:
					<ul>
						<li><a target="_blank" href="https://gitlab.com/frodobot/website/-/snippets/2271244">Privacy Policy</a></li>
						<li><a target="_blank" href="https://gitlab.com/frodobot/website/-/snippets/2271248">Terms of Service</a></li>
					</ul>
				</p>
			</div>
		`);
	}

	const promises = [
		getData('https://gitlab.com/api/v4/snippets/2271244', 'json', onError),
		getData('https://gitlab.com/api/v4/snippets/2271244/raw', 'text', onError),
		getData('https://gitlab.com/api/v4/snippets/2271248', 'json', onError),
		getData('https://gitlab.com/api/v4/snippets/2271248/raw', 'text', onError),
	];
	Promise.all(promises).then(([privacyPolicyData, privacyPolicy, termsOfServiceData, termsOfService]) => {
		if (error) return;

		const privacyPolicyDate = new Date(privacyPolicyData.updated_at);
		const privacyPolicyMarkdown = marked(privacyPolicy);

		const termsOfServiceDate = new Date(termsOfServiceData.updated_at);
		const termsOfServiceMarkdown = marked(termsOfService);

		setData(`
			<div>
				<p>This document covers:</p>
				<ul>
					<li>How we handle your data</li>
					<li>The use of the bot</li>
					<li>The use of this website</li>
				</ul>
			</div>
			<h2 id="privacy-policy">Privacy Policy</h2>
			<p>
				<em>Last updated: ${dateToString(privacyPolicyDate)}</em>
			</p>
			${privacyPolicyMarkdown}

			<h2 id="terms-of-service">Terms of Service</h2>
			<p>
				<em>Last updated: ${dateToString(termsOfServiceDate)}</em>
			</p>
			${termsOfServiceMarkdown}
		`);
	});
	return (
		<section style={{height: 'unset', minHeight: '100vh'}}>
			<Grid container spacing={0}>
				<Grid item xs={false} sm={false} md={3}/>
				<Grid item sm={12} xs={12} md={6}>
					<div style={{paddingTop: '50px', paddingLeft: '15px', paddingRight: '15px'}}>
						<Typography variant="h2" align="center">
							The Boring Stuff
						</Typography>
						{data ? (
							<div dangerouslySetInnerHTML={{__html: data}}/>
						) : (
							<div className="center">
								<img src="/static/img/loading.svg" alt="Loading"/>
							</div>
						)}
					</div>
				</Grid>
				<Grid item xs={false} sm={false} md={3}/>
			</Grid>
		</section>
	);
}
