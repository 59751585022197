function copy(text: string) {
	window.navigator.clipboard.writeText(text);
};

interface CopyTextProps {
	text: string;
	onCopy: () => void;
	children: React.ReactNode;
}

export default function CopyText(props: CopyTextProps) {
	function onClick() {
		copy(props.text);
		props.onCopy();
	}
	return (
		<div className="copy-text" onClick={onClick}>
			{props.children}
		</div>
	);
}
