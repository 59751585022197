import React from 'react';
import {List, Typography, ListItem, ListItemAvatar, Avatar, ListItemText, Divider} from '@mui/material';

interface LeaderboardProps {
}
interface LeaderboardState {
	leaderboard: any[];
	loading: boolean;
	error: boolean;
	errorText: string;
}

export default class Leaderboard extends React.Component<LeaderboardProps, LeaderboardState> {
	constructor(props: any) {
		super(props);
		this.state = {
			leaderboard: [],
			loading: true,
			error: false,
			errorText: '',
		};
	}

	async fetchLeadeboard() {
		try {
			const leaderboard = await (await fetch('/api/leaderboard/trivia')).json();
			if (!leaderboard || leaderboard.error) {
				this.setState({
					error: true,
					errorText: 'Hm, looks like something has gone wrong on our server, please try again later.',
				});
				return;
			} else {
				this.setState({
					leaderboard,
					loading: false,
				});
			}
		} catch (e) {
			this.setState({
				error: true,
				errorText: 'Hm, looks like there has been an error when trying to fetch the leaderboards, please try again later',
			});
		}
	}

	componentDidMount() {
		this.fetchLeadeboard();
	}

	render() {
		return (
			<section style={{height: 'unset', minHeight: '100vh'}}>
				{
					this.state.loading ? (
						<div className="center">
							{this.state.error ? (
								<Typography color="error" variant="h6" style={{textAlign: 'center'}}>{this.state.errorText}</Typography>
							) : (
								<img src="/static/img/loading.svg" alt="Loading..."/>
							)}
						</div>
					) : (
						<div style={{textAlign: 'center', paddingTop: '50px'}}>
							<Typography variant="h5"><b>Frodo Leaderboard</b></Typography>
							<Typography variant="h6">Trivia</Typography>
							<List>
								<div className="leaderboardList">
									{this.state.leaderboard.map((user, index) => (
										<div key={index}>
											<ListItem>
												<ListItemAvatar>
													<Avatar alt={user.name} src={user.avatar ? user.avatar : 'https://cdn.discordapp.com/embed/avatars/0.png'}>
														<Avatar alt={user.name} src="https://cdn.discordapp.com/embed/avatars/0.png"/>
													</Avatar>
												</ListItemAvatar>
												<ListItemText primary={user.name} secondary={`Current Score: ${user.score}`}/>
												{index <= 2 ? <img alt="trophy" src={`/static/img/${index + 1}.png`}/> : <Typography style={{paddingRight: '13px'}} variant="h6"><b>#{index + 1}</b></Typography>}
											</ListItem>
											{index !== this.state.leaderboard.length - 1 && <Divider/>}
										</div>
									))}
								</div>
							</List>
						</div>
					)
				}
			</section>
		);
	}
}
