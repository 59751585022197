import {Avatar, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography, Button, Tooltip} from '@mui/material';
import {Link} from 'react-router-dom';

import AddBoxIcon from '@mui/icons-material/AddBox';

import Fetch from '../../components/Fetch';
import {Quiz as QuizData} from '../../components/Quiz';
import Timestamp from '../../components/Timestamp';

export default function QuizList() {
	return (
		<Fetch<QuizData[]> src={['/api/quizzes/me']} center={true} onComplete={([quizzes]) => {
			return (
				<div style={{textAlign: 'center', paddingTop: '50px'}}>
					<Grid container spacing={5}>
						<Grid item xs={false} sm={2} md={3}/>
						<Grid item xs={12} sm={8} md={6}>
							<div style={{position: 'relative'}}>
								<Typography variant="h5"><b>Custom Quizzes</b></Typography>
								<Link to={quizzes.data.length >= 10 ? '' : '/quizzes/new'}>
									<div style={{marginRight: '5px', marginLeft: 'auto', position: 'absolute', top: '5px', right: '5px'}}>
										<Tooltip title={quizzes.data.length >= 10 ? 'You can only have a max of 10 quizzes' : ''}>
											<span>
												<Button disabled={quizzes.data.length >= 10} variant="contained" color="primary"><AddBoxIcon/>  New</Button>
											</span>
										</Tooltip>
									</div>
								</Link>
								{quizzes.data.length !== 0 ? (
									quizzes.data.map((quiz, index) => (
										<div key={index} style={{marginTop: '20px'}}>
											<Quiz quiz={quiz}/>
										</div>
									))
								) : (
									<Typography variant="h6" style={{textAlign: 'center'}}>You have no custom quizzes</Typography>
								)}
							</div>
						</Grid>
						<Grid item xs={false} sm={2} md={3}/>
					</Grid>
				</div>
			);
		}}/>
	);
}

function Quiz(props: {quiz: QuizData}) {
	return (
		<div style={{margin: '5px'}}>
			<Link style={{color: 'unset'}} to={`/quizzes/${props.quiz.quizId}`}>
				<List sx={{width: '100%', bgcolor: 'whitesmoke', padding: 0, borderRadius: '5px'}}>
					<ListItemButton>
						<ListItem>
							{props.quiz.image ? (
								<ListItemAvatar>
									<Avatar variant="rounded" src={`https://ik.imagekit.io/frodo/quizImages/${props.quiz.image}`} alt="Question Image"/>
								</ListItemAvatar>
							) : <></>}
							<ListItemText
								primary={props.quiz.name}
								secondary={props.quiz.description}
							/>
							<span style={{color: 'gray'}}>
								<Timestamp timestamp={props.quiz.lastChanged}/>
							</span>
						</ListItem>
					</ListItemButton>
				</List>
			</Link>
		</div>
	);
}
