import {Grid} from '@mui/material';

import {SectionProps} from './Section.d';

export default function Section(props: SectionProps) {
	if (!props.children) return <></>;
	if (props.nonResponsive) {
		return (
			<section>
				{props.children[0]}
			</section>
		);
	}
	return (
		<section className={props.className} style={props.style}>
			<Grid style={{width: '100%'}} container spacing={5}>
				<Grid item xs={false} sm={false} md={2}></Grid>
				<Grid style={{textAlign: 'center'}} item sm={12} xs={12} md={4}>
					<div className="part">
						{props.children[0]}
					</div>
				</Grid>
				<Grid style={{textAlign: 'center'}} item sm={12} xs={12} md={4}>
					<div className="part">
						{props.children[1]}
					</div>
				</Grid>
				<Grid item xs={false} sm={false} md={2}></Grid>
			</Grid>
		</section>
	);
};
