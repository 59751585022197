import {Slide, useScrollTrigger} from '@mui/material';

export default function HideOnSroll(props: {children: JSX.Element}) {
	const trigger = useScrollTrigger({target: window});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{props.children}
		</Slide>
	);
};
