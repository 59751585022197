import {Typography} from '@mui/material';
import React from 'react';


interface FetchProps<T> {
	src: string[];
	onComplete: (requests: FetchResponse<T>[]) => JSX.Element | void;
	onError?: () => void;
	errorText?: string;
	children?: JSX.Element;
	center?: boolean;
}
interface FetchState {
	loading: boolean;
	error: boolean;
	jsxToRender: JSX.Element;
}

interface FetchResponse<T> {
	data: T;
	request: Response;
}

export default class Fetch<T> extends React.Component<FetchProps<T>, FetchState> {
	props: FetchProps<T>;

	constructor(props: FetchProps<T>) {
		super(props);
		this.state = {
			loading: true,
			error: false,
			jsxToRender: <></>,
		};
		this.props = props;
	}

	static async get<T>(url: string): Promise<T> {
		const response = await fetch(url);
		return await response.json();
	}

	async fetchData() {
		try {
			const promises: Promise<Response>[] = [];
			this.props.src.forEach((src) => {
				promises.push(fetch(src));
			});
			const requests = await Promise.all(promises);
			const requestData = await Promise.all(requests.map((request) => request.json()));
			const requestDataToRun: FetchResponse<T>[] = [];
			requests.forEach((request, index) => {
				requestDataToRun.push({data: requestData[index], request});
			});
			this.setState({
				jsxToRender: this.props.onComplete(requestDataToRun) || this.props.children || <></>,
				loading: false,
			});
		} catch (e) {
			console.error(`Error when fetching ${this.props.src}:`, e);
			this.setState({
				error: true,
			});
			this.props.onError?.();
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {
		return this.state.loading ? (
			<span className={this.props.center ? 'center' : ''}>
				{this.state.error && !this.props.children ? (
					<Typography color="error" variant="h6" style={{textAlign: 'center'}}>{this.props.errorText || 'Hm, looks like something has gone wrong on our server, please try again later.'}</Typography>
				) : (
					this.props.children ? (
						this.props.children
					) : (
						<img src="/static/img/loading.svg" alt="Loading..."/>
					)
				)}
			</span>
		) : (
			this.state.jsxToRender
		);
	}
}
