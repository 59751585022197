import {Typography, Button} from '@mui/material';

import {getDiscordUser} from '../../components/AuthenticationCheck';
import QuizList from './QuizList';

export default function Quizzes() {
	return (
		<section style={{height: 'unset', minHeight: '100vh'}}>
			{getDiscordUser() ? (
				<QuizList/>
			) : (
				<div className="center" style={{textAlign: 'center'}}>
					<Typography variant="h6">You need to be logged in to create and view your quizzes</Typography>
					<Button startIcon={<img src="/static/img/social/discord-white.svg" style={{height: '1.5em'}}/>} variant="contained" color="primary" href="/api/discord/auth?redirect=/quizzes">Login with Discord</Button>
				</div>
			)}
		</section>
	);
}
