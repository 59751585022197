import {
	Dialog,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';
import React from 'react';

import {commands} from './commandData';

interface CommandPopupProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

export default function CommandPopup(props: CommandPopupProps) {
	function handleClick(ev: React.MouseEvent<HTMLElement>) {
		props.setOpen(false);

		const target = ev.target as HTMLDivElement;
		const hash = encodeURIComponent(target.innerText);
		window.history.pushState(null, '', `/commands#${hash}`);
		scrollIntoView(hash);
	}
	function scrollIntoView(elId: string) {
		const el = document.getElementById(elId);
		if (!el) return;
		const rect = el.getBoundingClientRect();
		window.scrollTo({
			top: rect.top + window.scrollY - 100,
			behavior: 'smooth',
		});
	}

	window.addEventListener('hashchange', (ev) => {
		ev.preventDefault();

		const hash = window.location.hash.replace('#', '');
		scrollIntoView(hash);
	});

	return (
		<Dialog className="commandPopup" open={props.open} onClose={() => props.setOpen(false)}>
			<DialogTitle>
                Frodo&apos;s Commands
			</DialogTitle>
			<DialogContent>
				<List>
					{
						commands.map((command, index) => (
							<ListItem key={index} button onClick={handleClick}>
								<ListItemText primary={command.name}/>
							</ListItem>
						))
					}
				</List>
			</DialogContent>
		</Dialog>
	);
};
