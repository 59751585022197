import {ListItem, Grid, Typography, TextField, IconButton} from '@mui/material';
import {Draggable} from 'react-beautiful-dnd';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';

import {Question as QuestionData} from '../../components/Quiz';

interface QuestionProps {
	question: QuestionData;
	index: number;
	onChange: (question: QuestionData) => void;
	onDelete: () => void;
}

export default function Question(props: QuestionProps) {
	const question = props.question;
	function dataChangeFunction(setValue: (newValue: string) => void) {
		return (event: React.ChangeEvent<HTMLInputElement>) => {
			setValue(event.target.value);
			props.onChange({...question, lastChanged: Date.now().toString()});
		};
	}
	return (
		<Draggable draggableId={props.index.toString()} index={props.index}>
			{(provided) => (
				<ListItem
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<span className="draggable-question">
						<Grid container spacing={1}>
							<Grid style={{position: 'relative'}} item xs={1}>
								<DragIndicatorIcon className="question-drag-icon"/>
							</Grid>
							<Grid item xs={11}>
								<div style={{position: 'relative', width: '100%'}}>
									<Typography variant="h6">Question #{props.index + 1}</Typography>
									<IconButton style={{position: 'absolute', top: '0px', right: '10px'}} onClick={props.onDelete}>
										<DeleteIcon color="error"/>
									</IconButton>
								</div>
								<TextField
									name="question"
									label="Question"
									value={question.question}
									variant="standard"
									fullWidth
									onChange={dataChangeFunction((value) => question.question = value)}
									required
								/>
								<TextField
									name="Correct Answer"
									label="Correct Answer"
									value={question.correctAnswers[0]}
									variant="standard"
									fullWidth
									onChange={dataChangeFunction((value) => question.correctAnswers[0] = value)}
									required
								/>
								<TextField
									name="Incorrect Answer"
									label="Incorrect Answer"
									value={question.incorrectAnswers[0]}
									variant="standard"
									fullWidth
									onChange={dataChangeFunction((value) => question.incorrectAnswers[0] = value)}
									required
								/>
								<TextField
									name="question"
									label="Incorrect Answer"
									value={question.incorrectAnswers[1]}
									variant="standard"
									fullWidth
									onChange={dataChangeFunction((value) => question.incorrectAnswers[1] = value)}
								/>
								<TextField
									name="question"
									label="Incorrect Answer"
									value={question.incorrectAnswers[2]}
									variant="standard"
									fullWidth
									onChange={dataChangeFunction((value) => question.incorrectAnswers[2] = value)}
								/>
							</Grid>
						</Grid>
					</span>
				</ListItem>
			)}
		</Draggable>
	);
}
