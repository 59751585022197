export default function Timestamp(props: {timestamp: string}) {
	const date = new Date(props.timestamp);
	const currentDate = new Date();
	const diff = currentDate.getTime() - date.getTime();
	const diffDate = new Date(diff);
	let dateString = '';
	if (diffDate.getUTCDate() > 1) {
		const days = diffDate.getUTCDate();
		dateString = `${days} day${days > 1 ? 's' : ''} ago`;
	} else {
		if (diffDate.getUTCHours() >= 1) {
			const hours = diffDate.getUTCHours();
			dateString = `${hours} hour${hours > 1 ? 's' : ''} ago`;
		} else if (diffDate.getUTCMinutes() >= 1) {
			const minutes = diffDate.getUTCMinutes();
			dateString = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
		} else {
			const seconds = diffDate.getUTCSeconds();
			dateString = `${seconds} second${seconds > 1 ? 's' : ''} ago`;
		}
	}
	return (
		<span>{dateString}</span>
	);
}
