import {Grid, FormControl, TextField, Typography, Button, Snackbar, Alert, IconButton, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import React, {useEffect, useState} from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import Fetch from '../../components/Fetch';
import {Category, CategorySelect, Difficulty, DifficultySelect} from './QuizSelects';
import {QuizData, Question} from '../../components/Quiz';
import Questions from './Questions';

interface QuizProps {
	quiz?: QuizData;
	newQuiz: boolean;
}

export default function Quiz(props: QuizProps) {
	const [imageUploadUrl, setImageUploadUrl] = useState<string | undefined>(props.quiz?.image ? `https://ik.imagekit.io/frodo/quizImages/${props.quiz.image}` : undefined);
	const [imageKitUrl, setImageKitUrl] = useState<string | undefined>(props.quiz?.image || undefined);
	const [errorBar, setErrorBar] = useState(false);
	const [errorBarText, setErrorBarText] = useState(<></>);
	const [successBar, setSuccessBar] = useState(false);
	const [loadingImg, setLoadingImg] = useState(false);
	const [categories, setCategories] = useState<Category[]>();
	const [difficulties, setDifficulties] = useState<Difficulty[]>();
	const [submitLoading, setSubmitLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const [selectedCategory, setSelectedCategory] = useState<string>(props.quiz?.categoryId || '');
	const [selectedDifficulty, setSelectedDifficulty] = useState<string>(props.quiz?.difficultyId || '');
	const [questions, setQuestions] = useState<Question[]>(props.quiz?.questions || []);

	async function fetchSelects() {
		const [categories, difficulties] = await Promise.all([
			Fetch.get<Category[]>('/api/quizzes/categories'),
			Fetch.get<Difficulty[]>('/api/quizzes/difficulties'),
		]);

		setCategories(categories);
		setDifficulties(difficulties);
	}
	useEffect(() => {
		fetchSelects()
			.catch((err) => {
				console.error(err);
				showErrorBar(<>Server error when fetching categories and difficulties! Please try again later</>);
			});
	}, []);

	function handleErrorBarClose() {
		setErrorBar(false);
	}
	function handleSuccessBarClose() {
		setSuccessBar(false);
	}
	function showErrorBarForImage(errorText = <>The file was too big! It must be smaller than 40MP and 100MB</>) {
		setErrorBarText(errorText);
		setErrorBar(true);
		setImageUploadUrl(undefined);
		setLoadingImg(false);
		resetInputFileUpload();
	}
	function showErrorBar(errorText: JSX.Element) {
		setErrorBarText(errorText);
		setErrorBar(true);
	}

	function uploadButtonClick() {
		if (loadingImg || imageUploadUrl) return;
		document.getElementById('imageUploadInput')?.click();
	}

	function resetUpload() {
		setImageUploadUrl(undefined);
		setImageKitUrl(undefined);
		setLoadingImg(false);
		resetInputFileUpload();
	}

	function resetInputFileUpload() {
		const inputElement = document.getElementById('imageUploadInput') as HTMLInputElement;
		if (!inputElement) return;
		inputElement.value = '';
	}

	async function onFileUplaod(event: React.ChangeEvent<HTMLInputElement>) {
		setLoadingImg(true);
		const file = event.target.files?.[0];
		if (!file) return;

		if (file.size > 40000000) {
			showErrorBarForImage();
			return;
		}

		const fileUrl = URL.createObjectURL(file);
		const imageEl = new Image();
		imageEl.src = fileUrl;
		imageEl.onload = () => {
			const size = imageEl.width * imageEl.height;
			if (size > 100000000) {
				showErrorBarForImage();
				return;
			}
		};

		const formData = new FormData();
		formData.append('file', file);
		formData.append('fileName', 'IMG');
		formData.append('folder', '/quizImages');

		let authentication;
		try {
			authentication = await fetch('/api/imagekit')
				.then((res) => res.json());
		} catch (e) {
			console.error(e);
			showErrorBarForImage(<>There was an error when uploading your image! Please try again or report the issue at <a href="https://help.frodo.fun/" target="_blank" rel="noreferrer">help.frodo.fun</a></>);
			return;
		}
		formData.append('publicKey', authentication.publicKey);
		formData.append('signature', authentication.signature || '');
		formData.append('expire', authentication.expire || 0);
		formData.append('token', authentication.token);

		try {
			const request = await fetch('https://upload.imagekit.io/api/v1/files/upload', {
				method: 'POST',
				body: formData,
			});
			if (request.status !== 200) {
				throw new Error('Failed to upload image');
			}
			const response = await request.json();
			setImageKitUrl(response.name);
		} catch (e) {
			console.error(e);
			showErrorBarForImage(<>There was an error when uploading your image! Please try again or check the image server&apos;s status at <a href="https://statuspage.imagekit.io/" target="_blank" rel="noreferrer">statuspage.imagekit.io</a></>);
			return;
		}
		setImageUploadUrl(fileUrl);
		setLoadingImg(false);
	}

	function formSubmit(ev: React.FormEvent<HTMLFormElement>) {
		ev.preventDefault();
		submitForm();
	}

	const [nameError, setNameError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);
	const [categoryError, setCategoryError] = useState(false);
	const [difficultyError, setDifficultyError] = useState(false);

	function errorElement(element: HTMLElement) {
		element.focus();
		element.scrollIntoView(false);
	}
	function clearAllErrors() {
		setNameError(false);
		setDescriptionError(false);
		setCategoryError(false);
		setDifficultyError(false);
		setErrorBar(false);
	}

	function submitForm() {
		clearAllErrors();

		const nameElement = document.getElementById('quizName') as HTMLInputElement;
		if (!nameElement.value.trim() || nameElement.value.trim().length < nameElement.minLength || nameElement.value.trim().length > nameElement.maxLength) {
			errorElement(nameElement);
			setNameError(true);
			return;
		}

		const descriptionElement = document.getElementById('quizDescription') as HTMLInputElement;
		if (!descriptionElement.value.trim() || descriptionElement.value.trim().length < descriptionElement.minLength || descriptionElement.value.trim().length > descriptionElement.maxLength) {
			setDescriptionError(true);
			errorElement(descriptionElement);
			return;
		}

		const categoryElement = document.getElementById('quizCategory') as HTMLInputElement;
		if (!selectedCategory) {
			setCategoryError(true);
			errorElement(categoryElement);
			return;
		}

		const difficultyElement = document.getElementById('quizDifficulty') as HTMLInputElement;
		if (!selectedDifficulty) {
			setDifficultyError(true);
			errorElement(difficultyElement);
			return;
		}

		if (questions.length < 2) {
			showErrorBar(<>You must have at least 2 questions in your quiz!</>);
			return;
		}
		if (questions.length > 20) {
			showErrorBar(<>You can only have a maximum of 20 questions in your quiz!</>);
			return;
		}
		for (const i in questions) {
			if (questions.hasOwnProperty(i)) {
				const question = questions[i];
				if (!question.question.trim()) {
					showErrorBar(<>Please make sure Question #{parseInt(i) + 1} has a question</>);
					return;
				}
				if (!question.correctAnswers[0].trim()) {
					showErrorBar(<>Please make sure Question #{parseInt(i) + 1} has a correct answer</>);
					return;
				}
				if (!question.incorrectAnswers.some((answer) => answer.trim().length >= 1)) {
					showErrorBar(<>Please make sure Question #{parseInt(i) + 1} has at least one wrong answer</>);
					return;
				}
			}
		}

		const questionsCopy: Question[] = JSON.parse(JSON.stringify(questions));
		const newQuestions = questionsCopy.map((question) => {
			question.question = question.question.trim();
			question.correctAnswers[0] = question.correctAnswers[0].trim();
			question.incorrectAnswers = question.incorrectAnswers.map((answer) => answer.trim());
			question.incorrectAnswers = question.incorrectAnswers.filter((answer) => answer.length >= 1);
			return question;
		});

		submitQuiz({
			name: nameElement.value.trim(),
			description: descriptionElement.value.trim(),
			categoryId: selectedCategory,
			difficultyId: selectedDifficulty,
			image: imageKitUrl,
			questions: newQuestions,
			quizId: props.quiz?.quizId || '',
			lastChanged: '',
		});
	}

	async function submitQuiz(quiz: QuizData) {
		try {
			setSubmitLoading(true);
			const request = await fetch(props.newQuiz ? '/api/quizzes/new' : `/api/quizzes/edit`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(quiz),
			});
			if (request.status !== 200) {
				throw new Error('Failed to submit quiz');
			} else {
				const {id} = await request.json();
				if (id) {
					window.location.href = `/quizzes/${id}`;
				} else {
					setSuccessBar(true);
				}
			}
			setSubmitLoading(false);
		} catch (e) {
			console.error(e);
			setSubmitLoading(false);
			showErrorBar(<>There was an error when submitting your quiz! Please try again or report your issue at <a href="https://help.frodo.fun" rel="noreferrer">help.frodo.fun</a></>);
		}
	}

	async function deleteQuiz() {
		try {
			setDeleteLoading(true);
			const request = await fetch(`/api/quizzes/delete?quizId=${props.quiz?.quizId || ''}`, {
				method: 'DELETE',
			});
			if (request.status !== 200) {
				throw new Error('Failed to delete quiz');
			} else {
				window.location.href = `/quizzes`;
			}
			setDeleteLoading(false);
		} catch (e) {
			console.error(e);
			setDeleteLoading(false);
			showErrorBar(<>There was an error when deleting your quiz! Please try again or report your issue at <a href="https://help.frodo.fun" rel="noreferrer">help.frodo.fun</a></>);
		}
	}

	return (
		<div style={{textAlign: 'center', paddingTop: '50px'}}>
			<Grid container spacing={5}>
				<Grid item xs={false} sm={2} md={3}/>
				<Grid item xs={12} sm={8} md={6}>
					<Typography variant="h5"><b>{props.newQuiz ? 'Create a New Quiz' : `Edit Quiz: ${props.quiz?.quizId}`}</b></Typography>
					{props.newQuiz ? <></> : (
						<Alert style={{margin: '10px'}} severity="info">
							To play the quiz, go to Discord and type <b>/playquiz quizid:{props.quiz?.quizId}</b>
						</Alert>
					)}
					<form onSubmit={formSubmit} id="quizForm" autoComplete="off" style={{margin: '5px', position: 'relative'}}>
						<FormControl fullWidth variant="outlined">
							<div className="quiz-bubble">
								<input readOnly className="hidden" type="text" name="id" value={props.quiz?.quizId || ''}/>
								<InputBox
									name="quizName"
									label="Quiz Name"
									minLength={5}
									maxLength={30}
									required
									helperText="Name must be between 5 and 30 characters"
									value={props.quiz?.name || ''}
									error={nameError}
								/>
								<InputBox
									name="quizDescription"
									label="Quiz Description"
									minLength={10}
									maxLength={300}
									required
									multiline
									helperText="Description must be between 10 and 300 characters"
									value={props.quiz?.description || ''}
									error={descriptionError}
								/>
								<Grid style={{position: 'relative'}} container spacing={2}>
									<Grid item xs={12} xl={6}>
										{categories && difficulties ? (
											<>
												<CategorySelect
													selected={props.quiz?.categoryId || ''}
													categories={categories}
													error={categoryError}
													onChange={setSelectedCategory}
												/>
												<DifficultySelect
													selected={props.quiz?.difficultyId || ''}
													difficulties={difficulties}
													error={difficultyError}
													onChange={setSelectedDifficulty}
												/>
											</>
										) : (
											<img alt="loading" src="/static/img/loading.svg"/>
										)}
									</Grid>
									<Grid item xs={12} xl={6}>
										<div className="image-upload-container">
											<input onInput={onFileUplaod} id="imageUploadInput" accept="image/*" type="file" className="hidden"/>
											<input readOnly name="image" value={imageKitUrl || ''} className="hidden"/>
											<Button disableRipple={imageUploadUrl ? true : false} onClick={uploadButtonClick} style={{height: '100%'}} fullWidth variant="outlined" component="span">
												{imageUploadUrl ? (
													<div className="upload-image">
														<div className="reset-upload-button">
															<IconButton className="reset-upload-button" onClick={resetUpload}>
																<DeleteIcon color="error"/>
															</IconButton>
														</div>
														<img src={imageUploadUrl} alt="Uploaded Image"/>
													</div>
												) : (
													loadingImg ? (
														<img src="/static/img/loading.svg" alt="Loading"/>
													) : (
														<div style={{display: 'block'}}>
															<Typography variant="h6">Upload Image</Typography>
															<Typography variant="body2"><i>Optional</i></Typography>
														</div>
													)
												)}
											</Button>
										</div>
									</Grid>
								</Grid>
							</div>

							<div className="quiz-bubble">
								<Questions onChange={(questions) => setQuestions(questions)} questions={props.quiz?.questions || []}/>
							</div>

							<input type="submit" id="formSumbitButton" className="hidden"/>
							<Stack direction="row" spacing={1}>
								<LoadingButton sx={{width: '100%'}} loading={submitLoading} startIcon={<SaveIcon/>} variant="contained" onClick={submitForm}>
									{props.newQuiz ? 'Create Quiz' : 'Save Quiz'}
								</LoadingButton>
								{props.newQuiz ? (<></>) : (
									<LoadingButton sx={{whiteSpace: 'nowrap'}} loading={deleteLoading} startIcon={<DeleteIcon style={{marginLeft: '5px'}}/>} variant="contained" color="error" onClick={deleteQuiz}>
										<span style={{marginRight: '5px'}}>Delete Quiz</span>
									</LoadingButton>
								)}
							</Stack>
						</FormControl>
					</form>
				</Grid>
				<Grid item xs={false} sm={2} md={3}/>
			</Grid>
			<Snackbar
				open={errorBar}
				onClose={handleErrorBarClose}
			>
				<Alert onClose={handleErrorBarClose} severity="error">
					{errorBarText}
				</Alert>
			</Snackbar>
			<Snackbar
				open={successBar}
				onClose={handleSuccessBarClose}
			>
				<Alert onClose={handleSuccessBarClose} severity="success">
					Your quiz was successfully updated!
				</Alert>
			</Snackbar>
		</div>
	);
}

interface InputBoxProps {
	name: string;
	label: string;
	minLength?: number;
	maxLength?: number;
	helperText?: string;
	multiline?: boolean;
	required?: boolean;
	value?: string;
	error: boolean;
}
interface HtmlInputProps {
	name: string;
	minLength?: number;
	maxLength?: number;
}

function InputBox(props: InputBoxProps) {
	const inputProps: HtmlInputProps = {
		name: props.name,
	};
	if (props.minLength) inputProps.minLength = props.minLength;
	if (props.maxLength) inputProps.maxLength = props.maxLength;
	return (
		<TextField
			inputProps={inputProps}
			className="new-quiz-field"
			fullWidth
			required={props.required}
			multiline={props.multiline}
			id={props.name}
			label={props.label}
			helperText={props.helperText}
			defaultValue={props.value}
			error={props.error}
		/>
	);
}
