import {MenuItem, TextField} from '@mui/material';
import {useState} from 'react';

export interface Category {
	categoryId: string;
	name: string;
}
export interface Difficulty {
	difficultyId: string;
	name: string;
}

export function CategorySelect(props: {categories: Category[], selected?: string, error: boolean, onChange: (value: string) => void}) {
	const [category, setCategory] = useState(props.selected || '');

	const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCategory(event.target.value);
		props.onChange(event.target.value);
	};

	return (
		<TextField
			select
			fullWidth
			required
			id="quizCategory"
			label="Category"
			helperText="Select a category for your quiz"
			value={category}
			onChange={handleCategoryChange}
			className="new-quiz-field"
			inputProps={{name: 'category'}}
			error={props.error}
		>
			{props.categories.map((category) => (
				<MenuItem key={category.categoryId} value={category.categoryId}>{category.name}</MenuItem>
			))}
		</TextField>
	);
}

export function DifficultySelect(props: {difficulties: Difficulty[], selected?: string, error: boolean, onChange: (value: string) => void}) {
	const [difficulty, setDifficulty] = useState(props.selected || '');

	const handleDifficultyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDifficulty(event.target.value);
		props.onChange(event.target.value);
	};

	return (
		<TextField
			select
			fullWidth
			required
			id="quizDifficulty"
			label="Difficulty"
			helperText="Select a difficulty for your quiz"
			value={difficulty}
			onChange={handleDifficultyChange}
			className="new-quiz-field"
			inputProps={{name: 'difficulty'}}
			error={props.error}
		>
			{props.difficulties.map((difficulty) => (
				<MenuItem key={difficulty.difficultyId} value={difficulty.difficultyId}>{difficulty.name}</MenuItem>
			))}
		</TextField>
	);
}
