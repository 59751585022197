
interface LogoProps {
	background: boolean;
	text: boolean;
	alt: string;
	className?: string;
}

export default function Logo(props: LogoProps) {
	const logosMap = {
		default: {
			'00': '/static/img/icons/nobg.svg',
			'01': '/static/img/icons/nobg-text.svg',
			'10': '/static/img/icons/bg-icon.svg',
			'11': '',
		},
		halloween: {
			'00': '/static/img/icons/halloween/nobg.svg',
			'01': '',
			'10': '/static/img/icons/halloween/bg-icon.svg',
			'11': '',
		},
	};

	return (
		<img className={props.className} src={logosMap.default[`${props.background ? 1 : 0}${props.text ? 1 : 0}`]} alt={props.alt}/>
	);
}
