import {Grid, useMediaQuery} from '@mui/material';

export interface CommandProps {
	children?: React.ReactNode[];
	id: string;
	value: number;
}

export default function Command(props: CommandProps) {
	if (!props.children) return <></>;
	const center: React.CSSProperties = {textAlign: 'center'};
	const right: React.CSSProperties = {textAlign: 'right'};
	const left: React.CSSProperties = {textAlign: 'left'};
	return (
		<>
			<div id={props.id} className={`command command-${props.value}`}>
				<Grid container spacing={5}>
					<Grid item xs={false} sm={false} md={2}/>
					{useMediaQuery('(max-width: 959px)') ? (
						<>
							<Grid style={center} item sm={12} xs={12} md={4}>
								{props.children[0]}
							</Grid>
							<Grid style={center} item sm={12} xs={12} md={4}>
								{props.children[1]}
							</Grid>
						</>
					) : (
						<>
							<Grid style={right} item sm={12} xs={12} md={4}>
								<div style={center}>
									{props.children[props.value % 2]}
								</div>
							</Grid>
							<Grid style={left} item sm={12} xs={12} md={4}>
								<div style={center}>
									{props.children[(props.value + 1) % 2]}
								</div>
							</Grid>
						</>
					)}
					<Grid item xs={false} sm={false} md={2}/>
				</Grid>
			</div>
		</>
	);
}
