import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './style.scss';

import AuthenticationCheck from './components/AuthenticationCheck';

import Home from './pages/home/Home';
import Legal from './pages/legal/Legal';
import NavBar from './components/NavBar';
import Feedback from './pages/feedback/Feedback';
import Leaderboard from './pages/leaderboard/Leaderboard';
import Commands from './pages/commands/Commands';
import Error from './pages/error/Error';
import Quizzes from './pages/quizzes/Quizzes';
import SelectQuiz from './pages/selectQuiz/SelectQuiz';
import Admin from './pages/admin/Admin';
import CookieBanner from './components/CookieBanner';

export default function App() {
	return (
		<AuthenticationCheck onReady={() => (
			<BrowserRouter>
				<NavBar/>

				<Routes>
					<Route path="/" element={<Home/>}/>
					<Route path="/feedback" element={<Feedback/>}/>
					<Route path="/leaderboard" element={<Leaderboard/>}/>
					<Route path="/commands" element={<Commands/>}/>
					<Route path="/legal" element={<Legal/>}/>
					<Route path="/quizzes" element={<Quizzes/>}/>
					<Route path="/quizzes/*" element={<SelectQuiz/>}/>
					<Route path="/admin" element={<Admin/>}/>

					<Route path="*" element={<Error/>}/>
				</Routes>

				<CookieBanner/>
			</BrowserRouter>
		)}/>
	);
}
